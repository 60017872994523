import { isAxiosError } from '../utils/validation/axios';
import { UMErrorSchema } from '../utils/validation/um-error';

export const PermissionDeniedErrorMsg = 'Permission denied!';
export const UserNotFoundMsg = 'User not found';

const isUserAlreadyRegisteredError = (message: string) =>
  /User registered with email ([^\s]+) is already a member of this company/.test(message);

export const ErrorCodes = {
  MINIMUM_ADMINS_REQUIRED: 'MINIMUM_ADMINS_REQUIRED',
  MINIMUM_QM_USER_REQUIRED: 'MINIMUM_QM_USER_REQUIRED',
  DOCUMENTS_HIDDEN_FROM_AUTHORS: 'DOCUMENTS_HIDDEN_FROM_AUTHORS', // Deprecated: for removal
  DOCUMENTS_HIDDEN_FROM_OWNERS: 'DOCUMENTS_HIDDEN_FROM_OWNERS',
  INVALID_USER_UPDATE: 'INVALID_USER_UPDATE',
  ALREADY_ACTIVE_USER: 'ALREADY_ACTIVE_USER',
  ACTIVE_USERS_LIMIT_REACHED: 'ACTIVE_USERS_LIMIT_REACHED',
  TOTAL_USERS_LIMIT_REACHED: 'TOTAL_USERS_LIMIT_REACHED',
  INVALID_EMAIL: 'INVALID_EMAIL',
} as const;

type UMErrorMessageMap = Partial<Record<keyof typeof ErrorCodes, string>>;

const SlugMap = new Map<string, keyof typeof ErrorCodes>([
  ['at_least_one_qm_required', ErrorCodes.MINIMUM_QM_USER_REQUIRED],
  ['total-users-limit-reached', ErrorCodes.TOTAL_USERS_LIMIT_REACHED],
  ['documents_hidden_from_authors', ErrorCodes.DOCUMENTS_HIDDEN_FROM_AUTHORS],
  ['documents_hidden_from_owners', ErrorCodes.DOCUMENTS_HIDDEN_FROM_OWNERS],
  ['at_least_one_admin_required', ErrorCodes.MINIMUM_ADMINS_REQUIRED],
]);

/**
 * @description Attempts to return a UM error code from the caught error.
 * If not an AxiosError or no errorCode is found, then it returns null.
 */
export const getErrorCode = (err: unknown): string | undefined => {
  if (isAxiosError(err)) {
    const parsedError = UMErrorSchema.safeParse(err.response?.data);
    if (parsedError.success) {
      const { message } = parsedError.data;
      if (message && isUserAlreadyRegisteredError(message)) {
        return ErrorCodes.ALREADY_ACTIVE_USER;
      }
      if (parsedError.data.slug && SlugMap.has(parsedError.data.slug)) {
        return SlugMap.get(parsedError.data.slug);
      }
    }
  }

  return undefined;
};

export const getErrorResponseMessageOrDefault = (error: unknown, defaultMessage: string) => {
  if (isAxiosError(error)) {
    const parsedError = UMErrorSchema.safeParse(error.response?.data);

    if (parsedError.success && parsedError.data.message) {
      return parsedError.data.message;
    }
  }
  return defaultMessage;
};

export const getErrorMessageOrDefault = (
  error: unknown,
  errorMessageMap: UMErrorMessageMap,
  defaultErrorMessage: string,
) => {
  const errorCode = getErrorCode(error);
  return errorMessageMap[errorCode as keyof typeof errorMessageMap] || defaultErrorMessage;
};
