import { AllCategories } from '../../types';

type StepTitlesType = Record<AllCategories | 'summary', string>;

export const StepTitles: StepTitlesType = {
  owner: 'Documents',
  changeRequestOwner: 'Change Requests',
  approver: 'Approval actions',
  reviewer: 'Review actions',
  issues: 'Issues',
  tasks: 'Tasks',
  summary: 'Confirm changes and remove user',
  default_owner: 'Default owner',
  eventTemplateOwner: 'Event Templates',
} as const;
