import { QMenuItemType } from '@qualio/ui-components';
import React from 'react';
import { z } from 'zod';
import { MedtechRole } from './medtechRole.types';

export type User = {
  id: number;
  email: string;
  invite_status: string;
  full_name: string;
  role: MedtechRole;
  groups: { id: number; name: string; include_in_reports: boolean }[]; // Should eventually reference the Group type when the snake-casing is fixed
  access: string[];
  isAdmin: boolean;
  permissions: Permissions[];
};

export const MtbeCompanyUsersItem = z.object({
  id: z.number(),
  email: z.string(),
  invite_status: z.string(),
  full_name: z.string(),
  staff: z.boolean(),
  active: z.boolean().nullish(),
  permissions: z.string().array(),
  usergroups: z.string().array(), // array of 1 item: user's role
  groups: z.object({ id: z.number(), name: z.string(), include_in_reports: z.boolean() }).array(),
  usercompany_id: z.number(),
  is_admin: z.boolean().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MtbeCompanyUsersItem = z.infer<typeof MtbeCompanyUsersItem>;

export type Permissions = Record<string, boolean>;

export type UserSelectList = User & {
  label: string;
};

export type Tag = {
  id: number;
  name: string;
};

export type Document = {
  id: number;
  title: string;
  status_id: string;
  code: string;
  version: string;
  tag_ids: string[];
  tags: Tag[];
  accessible_by_group_ids: number[];
  context: string;
  periodic_review_id?: number;
};

export type ChangeRequest = {
  id: number;
  created_at: number;
  closed_at?: number | null;
  is_deleted: boolean;
  owner: {
    id: number;
    full_name: string;
    usercompany_id: number;
  };
  status: string;
  title: string;
  company_id: number;
  last_modified_at: number;
  code_prefix: string;
  code_number: number;
  code: string;
};

export type IssuesOrTasksFromUserAssociations = {
  id: number;
  code: string;
  title: string;
  url: string;
};

export type EventTemplate = {
  id: number;
  prefix: string;
  name: string;
  active: boolean;
  company_id: number;
  sequence_id: number;
  lineage_id: number;
  major_version: number;
  minor_version: number;
  issues_count: number;
  default_owner_id?: number;
  default_owner?: string;
};

export type AssociationResponse = {
  owner: Document[];
  reviewer: Document[];
  approver: Document[];
  issues: Document[];
  tasks: Document[];
};

export type DocumentTableData = {
  checkbox?: JSX.Element;
  id: number;
  documentCode: React.ReactElement;
  title: string;
  status: string;
  tags: React.ReactElement;
};

export type ItemTableData = {
  checkbox?: JSX.Element;
  id: number;
  documentCode: React.ReactElement;
  title: string;
};

export type UserTableData = {
  checkbox?: JSX.Element;
  id: number;
  email: React.ReactElement;
  inviteStatus: string;
  fullName: string;
  role: string;
  groups: React.ReactElement;
  access: React.ReactElement;
};

export type UpdateUserByIdRequestBody = {
  role: string;
  fullName?: string | null;
  email?: string;
  isBilling: boolean;
  isAdmin?: boolean;
};

export type MTBEUpdateUserDTO = {
  company_id: number;
  role: string;
  full_name?: string | null;
  email?: string;
  permissions?: Record<string, boolean>[];
  is_admin: boolean;
};

export type UpdateManyByIdRequestBody = {
  full_name?: string | null;
  user_id: number;
  role: string;
  is_billing?: boolean;
}[];

export type CancelUserInviteRequest = {
  email: string;
};

export type UserQMenuItemType = QMenuItemType & { user: User };

export type UserAssociationState = {
  ownerDocumentData: Document[];
  approverDocumentData: Document[];
  reviewerDocumentData: Document[];
  issuesData: IssuesOrTasksFromUserAssociations[];
  taskData: IssuesOrTasksFromUserAssociations[];
};

export type UserAssociations = {
  approver: {
    documents: Document[];
    periodic_reviews: Document[];
  };
  issues: {
    issues: IssuesOrTasksFromUserAssociations[];
  };
  owner: {
    documents: Document[];
    change_requests: ChangeRequest[];
    event_templates: EventTemplate[];
  };
  reviewer: {
    documents: Document[];
  };
  tasks: {
    tasks: IssuesOrTasksFromUserAssociations[];
  };
  default_owner: {
    event_templates: EventTemplate[];
  };
};

export type DocumentOverrideItem = {
  userId: number | undefined;
  documentId: number;
  name: string;
};

export type NewAssociationOwner = {
  documentOverride: Map<number, DocumentOverrideItem>;
  selectedItems: number;
};

export type DocumentOverride = {
  userId: number;
  documentId: number;
};

export type ChangeRequestOverride = {
  userId: number;
  changeRequestId: number;
};

export type EventTemplateOverride = {
  userId: number;
  eventTemplateId: number;
};

export type NewUserAssociationOwner = {
  newDocumentOwner: NewAssociationOwner;
  newApprovalOwner: NewAssociationOwner;
  newReviewActionOwner: NewAssociationOwner;
  newIssueOwner: NewAssociationOwner;
  newTaskOwner: NewAssociationOwner;
};

export type DeleteUserByIdRequestBody = {
  id: number;
  isQm: boolean;
  newOwnerOverride?: DocumentOverride[] | [];
  newChangeRequestOwnerOverride?: ChangeRequestOverride[] | [];
  newApproverOverride?: DocumentOverride[];
  newReviewerOverride?: DocumentOverride[];
  newIssueOwnerOverride?: DocumentOverride[];
  newTaskOwnerOverride?: DocumentOverride[];
  newDefaultOwnerOverride?: EventTemplateOverride[];
  newEventTemplateOwnerOverride?: EventTemplateOverride[];
};

export type CreateUserRequestBody = {
  email: string;
  role: string;
  is_admin: boolean;
  is_billing: boolean;
  sendEmail?: boolean;
};

// This type is the group schema that is stored in MTBE
export type MTBEGroupEntity = {
  id: number;
  name: string;
  include_in_reports: boolean;
};

export type MTBEUserCompanyEntity = {
  id: number;
  name: string;
  groups?: MTBEGroupEntity[];
  created_time: number;
  status: string;
  trial_ends_at: number | null;
  logo: string | null;
  sso_enabled: boolean | null;
  sso_domain: string | null;
  sso_turned_on: boolean;
  currently_selected: boolean;
  active: boolean | null;
  ghost: boolean | null;
  permissions: string[];
  usergroups: Array<'read_only' | 'quality' | 'normal'>;
  invite_time: number | null;
  invite_status: string;
  pricing_plan: string | null;
  feature_flags?: object[];
  is_admin: boolean | null;
};

export type MTBEUserEntity = {
  id: number;
  email: string;
  full_name: string;
  staff: boolean;
  created_time: number;
  tz: string | null;
  companies: MTBEUserCompanyEntity[];
};

export type MTBEDeleteUserRequestBody = {
  id: number;
  is_qm: boolean;
  new_owner?: number;
  new_approver?: number;
  new_reviewer?: number;
  new_issue_owner?: number;
  new_task_owner?: number;
  new_owner_override?: object[];
  new_approver_override?: object[];
  new_reviewer_override?: object[];
  new_issue_owner_override?: object[];
  new_task_owner_override?: object[];
  new_change_request_owner_override?: object[];
  new_event_template_owner_override?: object[];
  new_event_template_entity_owner_override?: object[];
};
