import React, { ReactElement, useCallback, useState } from 'react';
import { QStep, QStepperProgress, QSteps } from '@qualio/ui-components';
import ReassignEntities from './ReassignEntities';
import Summary from './Summary';
import { CommonProps, AllCategories } from '../types';
import { StepStatusOptions } from './types';
import { isEmpty } from '../../../utils/object';
import { StepTitles } from './__displayStrings__';
import { EntitiesState, EntityDispatch } from '../state';

type StepsProps = {
  entityState: EntitiesState;
  dispatch: EntityDispatch;
} & Pick<CommonProps, 'users' | 'setNextButtonEnabled' | 'setShowNextButton' | 'incrementStep'>;

type StepProgressMapType = Record<AllCategories, StepStatusOptions>;

/**
 * This component is what handles rendering the individual steps as well as
 * storing the "status" of each step, i.e. "completed", "invalid", etc
 */
const Steps = ({
  users,
  setNextButtonEnabled,
  setShowNextButton,
  incrementStep,
  entityState,
  dispatch,
}: StepsProps): ReactElement => {
  const [stepStatus, setStepStatus] = useState<StepProgressMapType>({
    owner: 'default',
    changeRequestOwner: 'default',
    approver: 'default',
    reviewer: 'default',
    issues: 'default',
    tasks: 'default',
    default_owner: 'default',
    eventTemplateOwner: 'default',
  });

  const setStatusForStep = (stepName: keyof StepProgressMapType, status: StepStatusOptions) => {
    setStepStatus((currentStepStatus: StepProgressMapType) => {
      return {
        ...currentStepStatus,
        [stepName]: status,
      };
    });
  };
  const setOwnerStatus = useCallback(setStatusForStep.bind(null, 'owner'), []);
  const setChangeRequestOwnerStatus = useCallback(setStatusForStep.bind(null, 'changeRequestOwner'), []);
  const setApproverStatus = useCallback(setStatusForStep.bind(null, 'approver'), []);
  const setReviewerStatus = useCallback(setStatusForStep.bind(null, 'reviewer'), []);
  const setIssuesStatus = useCallback(setStatusForStep.bind(null, 'issues'), []);
  const setTasksStatus = useCallback(setStatusForStep.bind(null, 'tasks'), []);
  const setDefaultOwnerStatus = useCallback(setStatusForStep.bind(null, 'default_owner'), []);
  const setEventTemplateOwnerStatus = useCallback(setStatusForStep.bind(null, 'eventTemplateOwner'), []);

  const {
    owner: ownerItems,
    changeRequestOwner: changeRequestOwnerItems,
    approver: approverItems,
    reviewer: reviewerItems,
    issues: issueItems,
    tasks: taskItems,
    default_owner: defaultOwnerItems,
    eventTemplateOwner: eventTemplateOwnerItems,
  } = entityState;

  return (
    <QSteps>
      <QStepperProgress />
      {!isEmpty(ownerItems) && (
        <QStep variant={stepStatus.owner} title={StepTitles.owner}>
          <ReassignEntities
            key="ownerStep"
            category="owner"
            title={StepTitles.owner}
            setNextButtonEnabled={setNextButtonEnabled}
            incrementStep={incrementStep}
            items={ownerItems}
            dispatch={dispatch}
            users={users}
            updateStepStatus={setOwnerStatus}
          />
        </QStep>
      )}
      {!isEmpty(changeRequestOwnerItems) && (
        <QStep variant={stepStatus.changeRequestOwner} title={StepTitles.changeRequestOwner}>
          <ReassignEntities
            key="changeRequestOwnerStep"
            category="changeRequestOwner"
            title={StepTitles.changeRequestOwner}
            setNextButtonEnabled={setNextButtonEnabled}
            incrementStep={incrementStep}
            items={changeRequestOwnerItems}
            dispatch={dispatch}
            users={users}
            updateStepStatus={setChangeRequestOwnerStatus}
          />
        </QStep>
      )}
      {!isEmpty(eventTemplateOwnerItems) && (
        <QStep variant={stepStatus.eventTemplateOwner} title={StepTitles.eventTemplateOwner}>
          <ReassignEntities
            key="eventTemplateOwnerStep"
            category="eventTemplateOwner"
            title={StepTitles.eventTemplateOwner}
            setNextButtonEnabled={setNextButtonEnabled}
            incrementStep={incrementStep}
            items={eventTemplateOwnerItems}
            dispatch={dispatch}
            users={users}
            updateStepStatus={setEventTemplateOwnerStatus}
          />
        </QStep>
      )}
      {!isEmpty(approverItems) && (
        <QStep variant={stepStatus.approver} title={StepTitles.approver}>
          <ReassignEntities
            key="approverStep"
            category="approver"
            title={StepTitles.approver}
            setNextButtonEnabled={setNextButtonEnabled}
            incrementStep={incrementStep}
            items={approverItems}
            dispatch={dispatch}
            users={users}
            updateStepStatus={setApproverStatus}
          />
        </QStep>
      )}
      {!isEmpty(reviewerItems) && (
        <QStep variant={stepStatus.reviewer} title={StepTitles.reviewer}>
          <ReassignEntities
            key="reviewerStep"
            category="reviewer"
            title={StepTitles.reviewer}
            setNextButtonEnabled={setNextButtonEnabled}
            incrementStep={incrementStep}
            items={reviewerItems}
            dispatch={dispatch}
            users={users}
            updateStepStatus={setReviewerStatus}
          />
        </QStep>
      )}
      {!isEmpty(issueItems) && (
        <QStep variant={stepStatus.issues} title={StepTitles.issues}>
          <ReassignEntities
            key="issuesStep"
            category="issues"
            title={StepTitles.issues}
            setNextButtonEnabled={setNextButtonEnabled}
            incrementStep={incrementStep}
            items={issueItems}
            dispatch={dispatch}
            users={users}
            updateStepStatus={setIssuesStatus}
          />
        </QStep>
      )}
      {!isEmpty(taskItems) && (
        <QStep variant={stepStatus.tasks} title={StepTitles.tasks}>
          <ReassignEntities
            key="tasksStep"
            category="tasks"
            title={StepTitles.tasks}
            setNextButtonEnabled={setNextButtonEnabled}
            incrementStep={incrementStep}
            items={taskItems}
            dispatch={dispatch}
            users={users}
            updateStepStatus={setTasksStatus}
          />
        </QStep>
      )}
      {!isEmpty(defaultOwnerItems) && (
        <QStep variant={stepStatus.default_owner} title={StepTitles.default_owner}>
          <ReassignEntities
            key="defaultOwnerStep"
            category="default_owner"
            title={StepTitles.default_owner}
            setNextButtonEnabled={setNextButtonEnabled}
            incrementStep={incrementStep}
            items={defaultOwnerItems}
            dispatch={dispatch}
            users={users}
            updateStepStatus={setDefaultOwnerStatus}
          />
        </QStep>
      )}
      <QStep title={StepTitles.summary}>
        <Summary
          key="summaryStep"
          title={StepTitles.summary}
          setShowNextButton={setShowNextButton}
          entityState={entityState}
        />
      </QStep>
    </QSteps>
  );
};

export default Steps;
