import { getGroups, getUserAssociations, getUsersByCompanyId } from '../../../api/medtech-api';
import {
  PotentialNewOwners,
  ReassignedEntity,
  ReassignResultObject,
} from '../../../components/ReassignQualioEntities/types';
import { AllEntitiesReassigned } from '../../../components/ReassignQualioEntities/utils';
import { UserRemovalService } from '../../../services/delete/deleteUser';
import {
  ChangeRequestOverride,
  DeleteUserByIdRequestBody,
  DocumentOverride,
  EventTemplateOverride,
  InviteStatusMap,
  User,
} from '../../../types';
import { UsergroupsList } from '../../../utils/umfe.enums';

/**
 * Takes a list of Documents or Items and creates equivalent override object
 * needed by the BE to reassign the entity to a new owner
 */
const convertItemsToNewOwnerObjects = (itemArray: ReassignedEntity[]): DocumentOverride[] => {
  return itemArray.map((item) => ({ userId: item.newOwner.id, documentId: item.id }));
};

/**
 * Takes a list of Change Requests and creates equivalent override object
 * needed by the BE to reassign the entity to a new owner
 */
const convertCRItemsToNewOwnerObjects = (itemArray: ReassignedEntity[]): ChangeRequestOverride[] => {
  return itemArray.map((item) => ({ userId: item.newOwner.id, changeRequestId: item.id }));
};

const convertEventTemplateItemsToNewOwnerObjects = (itemArray: ReassignedEntity[]): EventTemplateOverride[] => {
  return itemArray.map((item) => ({ userId: item.newOwner.id, eventTemplateId: item.id }));
};

/**
 * Takes all of the documents that have been reassigned and processes them to
 * create the payload needed for the backend to update ownership before calling
 * the UM backend
 */
export const deleteUserWithDocuments = (companyId: number, userId: number, reassignmentData: ReassignResultObject) => {
  const {
    owner,
    changeRequestOwner,
    approver,
    reviewer,
    issues,
    tasks,
    default_owner: defaultOwner,
    eventTemplateOwner,
  } = reassignmentData;

  if (
    !AllEntitiesReassigned(owner) ||
    !AllEntitiesReassigned(changeRequestOwner) ||
    !AllEntitiesReassigned(approver) ||
    !AllEntitiesReassigned(reviewer) ||
    !AllEntitiesReassigned(issues) ||
    !AllEntitiesReassigned(tasks) ||
    !AllEntitiesReassigned(defaultOwner) ||
    !AllEntitiesReassigned(eventTemplateOwner)
  ) {
    throw new Error('All Qualio Entities need reassigned before removing a user');
  }

  const deleteData: DeleteUserByIdRequestBody = {
    id: userId,
    isQm: false,
    newOwnerOverride: convertItemsToNewOwnerObjects(owner),
    newChangeRequestOwnerOverride: convertCRItemsToNewOwnerObjects(changeRequestOwner),
    newApproverOverride: convertItemsToNewOwnerObjects(approver),
    newReviewerOverride: convertItemsToNewOwnerObjects(reviewer),
    newIssueOwnerOverride: convertItemsToNewOwnerObjects(issues),
    newTaskOwnerOverride: convertItemsToNewOwnerObjects(tasks),
    newDefaultOwnerOverride: convertEventTemplateItemsToNewOwnerObjects(defaultOwner),
    newEventTemplateOwnerOverride: convertEventTemplateItemsToNewOwnerObjects(eventTemplateOwner),
  };

  return UserRemovalService.deleteUser(companyId, userId, deleteData);
};

/**
 * Gets a list of all of the users that the Qualio Entities can be reassigned to
 * which means only users whose invitation status is not pending, users who are
 * more than just "basic" users, users with a full name, and not the user that
 * is being removed. Also returns the user that is being removed so that we can
 * display their name in the UI.
 */
export const getUsers = async (
  companyId: number,
  idOfUserToBeRemoved: number,
): Promise<{
  userToBeRemoved: User | undefined;
  potentialNewOwners: PotentialNewOwners[];
}> => {
  const { data } = await getUsersByCompanyId(companyId);
  const potentialNewOwners = data
    .filter((user) => {
      return (
        user.invite_status !== InviteStatusMap.pending &&
        user.id !== idOfUserToBeRemoved &&
        user.role !== UsergroupsList.BASIC.value &&
        user.full_name !== ''
      );
    })
    .map(({ id, full_name: fullName, groups }) => {
      return {
        value: `${id}`,
        label: `${fullName}`,
        groups,
      };
    });
  const userToBeRemoved = data.find((user) => user.id === idOfUserToBeRemoved);

  return {
    userToBeRemoved,
    potentialNewOwners,
  };
};

// Fetches all the Qualio Entities currently assigned to the user being removed
export const getItemsThatNeedReassigned = async (userId: number, companyId: number) => {
  const { data } = await getUserAssociations(userId, companyId);

  return data;
};

// Fetches all the Groups for the Company and creates an ID map for lookup later
export const getGroupMap = async (companyId: number) => {
  const { data } = await getGroups(companyId);

  return data.reduce((aggregate, group) => {
    return {
      ...aggregate,
      [group.id]: group,
    };
  }, {});
};
