import React from 'react';
import { QBadge, QCheckbox, deprecated, QSelect, QSelectItem, QTag } from '@qualio/ui-components';
import { StopPropagation } from '../../../../utils/events';
import {
  ReassignItemsMap,
  ReassignDocumentsMap,
  PotentialNewOwners,
  AllCategories,
  ReassignDocument,
  ReassignChangeRequestsMap,
  ReassignEventTemplatesMap,
  ReassignItems,
  ReassignChangeRequest,
  ReassignEventTemplate,
} from '../../types';
import { getAssociationStatus } from '../../../../utils/translator';
import { ColumnLabels } from './__displayStrings__/TableBuilder';
import { buildDispatchObject, EntityDispatch } from '../../state';

type CheckBoxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => void;

// Builds the column data for both "item" and "doc" tables
export const BuildColumns = (
  category: AllCategories,
  allSelected: boolean,
  handleHeaderCheckboxChange: CheckBoxChangeHandler,
) => {
  const tableTypeByCategory = {
    owner: 'doc',
    reviewer: 'doc',
    approver: 'doc',
    changeRequestOwner: 'item',
    issues: 'item',
    tasks: 'item',
    default_owner: 'event_template',
    eventTemplateOwner: 'event_template',
  } as const;
  const tableType = tableTypeByCategory[category];

  const checkBoxColumn = {
    Header: (
      <QCheckbox
        aria-label="table_checkbox"
        size="md"
        isChecked={allSelected}
        onClick={StopPropagation}
        onChange={handleHeaderCheckboxChange}
      />
    ),
    disableSortBy: true,
    width: '2%',
    accessor: 'checkbox',
  };
  const idColumn = {
    Header: ColumnLabels.id,
    width: '5%',
    accessor: 'itemCodeOrPrefix',
  };
  const titleColumn = {
    Header: ColumnLabels.title,
    accessor: 'titleOrName',
    width: tableType === 'item' ? '18%' : '12%',
  };
  const newOwnerColumn = {
    Header: ColumnLabels.newOwner,
    accessor: 'newOwner',
    width: tableType === 'item' ? '5%' : '15%',
  };
  const statusColumn = {
    Header: ColumnLabels.status,
    accessor: 'status',
    width: '10%',
  };
  const tagColumn = {
    Header: ColumnLabels.tags,
    accessor: 'tags',
    width: '5%',
  };
  const columnsByTableType = {
    item: [checkBoxColumn, idColumn, titleColumn, newOwnerColumn],
    doc: [checkBoxColumn, idColumn, titleColumn, statusColumn, tagColumn, newOwnerColumn],
    event_template: [
      checkBoxColumn,
      { ...idColumn, Header: ColumnLabels.prefix },
      { ...titleColumn, Header: ColumnLabels.name },
      newOwnerColumn,
    ],
  } as const;

  return columnsByTableType[tableType];
};

const getNeededValues = (entity: ReassignDocument | ReassignItems | ReassignChangeRequest | ReassignEventTemplate) => {
  const neededValues = {
    id: entity.id,
    newOwner: entity.newOwner,
    codeOrPrefix: '',
    titleOrName: '',
  };

  if ('code' in entity) {
    neededValues.codeOrPrefix = entity.code;
  } else if ('prefix' in entity) {
    neededValues.codeOrPrefix = entity.prefix;
  }

  if ('title' in entity) {
    neededValues.titleOrName = entity.title;
  } else if ('name' in entity) {
    neededValues.titleOrName = entity.name;
  }

  return neededValues;
};

// Builds the row data for "item", "doc", and "event template" tables
export const BuildRows = (
  category: AllCategories,
  dispatch: EntityDispatch,
  entities: ReassignDocumentsMap | ReassignItemsMap | ReassignChangeRequestsMap | ReassignEventTemplatesMap,
  selected: Set<number>,
  setSelected: (selectedItems: Set<number>) => void,
  users: PotentialNewOwners[],
): deprecated.QDataRow[] => {
  return Object.keys(entities).map((entityId) => {
    const qualioEntity = entities[entityId];

    const { id, newOwner, codeOrPrefix, titleOrName } = getNeededValues(qualioEntity);
    const currentValue = newOwner ? newOwner.id.toString() : undefined;

    const selectBoxChangeHandler = (changedItem: QSelectItem) => {
      const newMap = {
        ...entities,
        [id]: {
          ...qualioEntity,
          newOwner: {
            id: Number(changedItem.value),
            name: changedItem.label,
          },
        },
      };

      dispatch(buildDispatchObject(category, newMap));
    };

    const checkboxChangeHandler = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
      const newSelected = new Set([...selected]);

      if (changeEvent.currentTarget.checked) {
        newSelected.add(id);
      } else {
        newSelected.delete(id);
      }

      setSelected(newSelected);
    };

    const baseRow = {
      checkbox: (
        <QCheckbox
          aria-label="table_checkbox"
          size="md"
          isChecked={selected.has(id)}
          onChange={checkboxChangeHandler}
        />
      ),
      id,
      itemCodeOrPrefix: <QBadge>{codeOrPrefix}</QBadge>,
      titleOrName,
      newOwner: (
        <QSelect
          aria-label="documents_single_select"
          value={currentValue}
          options={users}
          onChange={(item) => item && selectBoxChangeHandler(item)}
        />
      ),
    };

    if (
      category === 'issues' ||
      category === 'tasks' ||
      category === 'changeRequestOwner' ||
      category === 'default_owner' ||
      category === 'eventTemplateOwner'
    ) {
      return baseRow;
    }

    const { status_id: statusId, tags } = qualioEntity as ReassignDocument;

    return {
      ...baseRow,
      status: getAssociationStatus(statusId),
      tags: (
        <>
          {tags.map((tag) => (
            <QTag key={tag.id}>{tag.name}</QTag>
          ))}
        </>
      ),
    };
  });
};
