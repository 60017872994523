import { cancelUserInvite, deleteUserInMedtech, getUserById } from '../../api/medtech-api';
import { CancelUserInviteRequest, DeleteUserByIdRequestBody } from '../../types';
import { buildMTBEDeleteUserPayload } from './deleteUserPayload';

export class UserRemovalService {
  static deleteUser = async (companyId: number, userId: number, data: DeleteUserByIdRequestBody) => {
    const mtbeDeleteUserByIdRequestDto = {
      id: data.id,
      is_qm: data.isQm,
      ...buildMTBEDeleteUserPayload('new_owner', data.newOwnerOverride),
      ...buildMTBEDeleteUserPayload('new_approver', data.newApproverOverride),
      ...buildMTBEDeleteUserPayload('new_reviewer', data.newReviewerOverride),
      ...buildMTBEDeleteUserPayload('new_change_request_owner', data.newChangeRequestOwnerOverride),
      ...buildMTBEDeleteUserPayload('new_issue_owner', data.newIssueOwnerOverride),
      ...buildMTBEDeleteUserPayload('new_task_owner', data.newTaskOwnerOverride),
      ...buildMTBEDeleteUserPayload('new_event_template_owner', data.newDefaultOwnerOverride),
      ...buildMTBEDeleteUserPayload('new_event_template_entity_owner', data.newEventTemplateOwnerOverride),
    };
    await deleteUserInMedtech(companyId, userId, mtbeDeleteUserByIdRequestDto);
  };

  static cancelUserInvite = async (companyId: number, userId: number, data: CancelUserInviteRequest) => {
    const user = await getUserById(companyId, userId, true);

    const { inviteStatus } = user;

    if (!!inviteStatus && ['pending', 'declined'].includes(inviteStatus)) {
      if (user.email !== data.email) {
        // If email in body doesn't match the email on the user, then we got weird data mismatch. Throw error.
        throw new Error(`Email for user being canceled does not match the email provided (${data.email}).`);
      }

      await cancelUserInvite(companyId, data.email);
    }

    if (inviteStatus === 'accepted') {
      throw new Error(
        'User has already accepted invitation! You cannot cancel invitation but you can delete user company account if needed.',
      );
    }
  };
}
