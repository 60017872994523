import React, { ReactElement, useEffect } from 'react';
import { QAlert, QDivider, QHeading } from '@qualio/ui-components';
import { QStackContainer } from '../../../../styles/Users.styles';
import { isEmpty } from '../../../../utils/object';
import { CommonProps } from '../../types';
import ReassignOwnerSummaryItem from './ReassignOwnerSummaryItem';
import StandardSummaryItem from './StandardSummaryItem';
import * as DisplayStrings from './__displayStrings__';
import { EntitiesState } from '../../state';
import { ReassignOwnerSummaryItemType } from '../types';
import ReassignEventTemplateSummaryItem from './ReassignEventTemplateSummaryItem';

type SummaryProps = Pick<CommonProps, 'setShowNextButton'> & {
  title: string;
  entityState: EntitiesState;
};

const Summary = ({ title, setShowNextButton, entityState }: SummaryProps): ReactElement => {
  useEffect(() => {
    setShowNextButton(false);

    return () => setShowNextButton(true);
  }, []);

  const {
    owner: ownerItems,
    changeRequestOwner: changeRequestOwnerItems,
    approver: approverItems,
    reviewer: reviewerItems,
    issues: issueItems,
    tasks: taskItems,
    default_owner: defaultOwnerItems,
    eventTemplateOwner: eventTemplateOwnerItems,
  } = entityState;

  return (
    <QStackContainer direction="column" spacing={3}>
      <QHeading size="md">{title}</QHeading>
      <QAlert description={DisplayStrings.AlertText} status="info" />
      {!isEmpty(ownerItems) && (
        <>
          <ReassignOwnerSummaryItem items={ownerItems} type={ReassignOwnerSummaryItemType.document} />
          <QDivider />
        </>
      )}
      {!isEmpty(changeRequestOwnerItems) && (
        <>
          <ReassignOwnerSummaryItem items={changeRequestOwnerItems} type={ReassignOwnerSummaryItemType.changeRequest} />
          <QDivider />
        </>
      )}
      {!isEmpty(approverItems) && (
        <>
          <StandardSummaryItem items={approverItems} summaryCategory="approver" />
          <QDivider />
        </>
      )}

      {!isEmpty(reviewerItems) && (
        <>
          <StandardSummaryItem items={reviewerItems} summaryCategory="reviewer" />
          <QDivider />
        </>
      )}

      {!isEmpty(issueItems) && (
        <>
          <StandardSummaryItem items={issueItems} summaryCategory="issues" />
          <QDivider />
        </>
      )}
      {!isEmpty(taskItems) && (
        <>
          <StandardSummaryItem items={taskItems} summaryCategory="tasks" />
          <QDivider />
        </>
      )}
      {(!isEmpty(defaultOwnerItems) || !isEmpty(eventTemplateOwnerItems)) && (
        <>
          <ReassignEventTemplateSummaryItem
            defaultAssigneeItems={defaultOwnerItems}
            ownerItems={eventTemplateOwnerItems}
          />
          <QDivider />
        </>
      )}
    </QStackContainer>
  );
};

export default Summary;
