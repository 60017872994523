import React, { useCallback } from 'react';
import { QBox, QCenter, QDivider, QStack } from '@qualio/ui-components';
import { useFormikContext } from 'formik';
import { MedtechRole } from '../../../types/medtechRole.types';
import { MedtechPermissionsByRolesMap } from '../../../utils/validation/permissions';
import GeneralSection from './GeneralSection';
import {
  CanCreateIssue,
  CanManageQualityEventWorkflow,
  CanViewQE,
  CanWorkOnIssue,
  CanAccessSuppliers,
  CanAccessAPICapabilities,
  CanAccessDesignControls,
} from '../../../utils/validation/medtechPermissions';
import RoleWatcher from './RoleWatcher';
import { PermissionsSection } from './PermissionsSection';

type EditUserFormProps = {
  inviteIsPending: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  userRole: MedtechRole;
  defaultPermissions: MedtechPermissionsByRolesMap;
  onCancel: () => void;
};

const EditUserForm = ({
  inviteIsPending,
  isLoading,
  isDisabled,
  defaultPermissions,
  userRole,
  onCancel,
}: EditUserFormProps) => {
  const { setFieldValue } = useFormikContext();
  const defaultPermissionsByRole = defaultPermissions[userRole];

  const resetPermissions = useCallback(() => {
    setFieldValue('canViewQE', defaultPermissionsByRole.includes(CanViewQE));
    setFieldValue('canCreateQE', defaultPermissionsByRole.includes(CanCreateIssue));
    setFieldValue('canEditQE', defaultPermissionsByRole.includes(CanWorkOnIssue));
    setFieldValue('canManageQE', defaultPermissionsByRole.includes(CanManageQualityEventWorkflow));
    setFieldValue('canAccessSuppliers', defaultPermissionsByRole.includes(CanAccessSuppliers));

    setFieldValue('canAccessAPICapabilities', defaultPermissionsByRole.includes(CanAccessAPICapabilities));
    setFieldValue('canAccessDesignControls', defaultPermissionsByRole.includes(CanAccessDesignControls));
  }, [setFieldValue, defaultPermissionsByRole]);

  return (
    <QCenter>
      <QBox maxWidth="40rem">
        <QStack direction={['column']}>
          <GeneralSection inviteIsPending={inviteIsPending} />
          <QDivider />
          <RoleWatcher resetPermissions={resetPermissions} role={userRole} />
          <PermissionsSection
            resetPermissions={resetPermissions}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onCancel={onCancel}
          />
        </QStack>
      </QBox>
    </QCenter>
  );
};

export default EditUserForm;
